// import axios from '@/axios/axios/index.js';
// import store from '@/store';
// import $interfaces from '@/axios/interfaces';

// const flatten = array => {
//   const flattend = [];
//   (function flat(array) {
//     array.forEach(function (el) {
//       if (Array.isArray(el.children)) {
//         flattend.push(el);
//         flat(el.children);
//       } else {
//         flattend.push(el);
//       }
//     });
//   })(array);
//   return flattend;
// };

/**
 * 获取当前登录账号的所有权限，菜单权限、设备权限、按钮权限
 */
// const getCurrentUserAllPermissions = () => {
//   return $interfaces.oauth.getCurrentUserAllPermissions();
// };

export default async next => {
  // if (!store.state.userInfo.permissions) {
  //   const user = JSON.parse(localStorage.getItem('user'));
  //   const userInfo = {
  //     ...user,
  //     permissions: user.permissions || (await getCurrentUserAllPermissions())
  //   };

  //   userInfo.permissions.flatten_menus = flatten(userInfo.permissions.menu)
  //     .map(menu => menu.component)
  //     .filter(menu => menu);

  //   localStorage.setItem('user', JSON.stringify(userInfo));
  // }

  // store.commit('setUserInfo', JSON.parse(localStorage.getItem('user')));

  next();
};
